<template>
  <div class="account-live-child">
    <div v-if="showMask" class="mask" @click.stop="onCloseToast" />
    <div
      v-if="isMobile"
      class="card-margin"
    >
      <el-table
        style="width: 100%;"
        :indent="28"
        :data="accountLiveSummaryTableData"
        row-key="name"
        :span-method="arraySpanMethod"
        stripe
        lazy
      >
        <el-table-column align="center">
          <template slot="header">
            <span class="font-weight color-main">进行中的直播&会议活动</span>
          </template>
          <el-table-column
            prop="meetingMoney"
            align="center"
            min-width="120"
            label="任务类型"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.name || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="reviewing"
            width="100"
            align="center"
            label="待提交审核"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.reviewing || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="checking" align="center" label="审核中">
            <template slot-scope="scope">
              <span>{{ scope.row.checking || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="wait" align="center" label="审核成功待采购">
            <template slot-scope="scope">
              <span>{{ scope.row.pay || 0 }}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-col class="flex-around vertical-center desc-row">
        <div class="desc-item">
          <el-popover
            placement="top-start"
            title="账户当日期初余额为账户前一日期末余额"
            trigger="hover"
          >
            <span slot="reference" class="color-main">期初余额&emsp;<i class="el-icon-question" /></span>
          </el-popover>
          <div class="font-s28 font-weight">{{ accountLiveSummaryData.startBalance || 0 }}</div>
          <div class="color-desc">
            截止至<br>
            {{ $moment().subtract(1,'day').endOf('day').format('YYYY.MM.DD HH:mm:ss') }}
          </div>
        </div>
        <div class="desc-item self-align-center">
          <el-divider direction="vertical" />
        </div>
        <div class="desc-item">
          <span class="color-main">实时可支配余额</span>
          <div class="font-s28 font-weight">{{ accountLiveSummaryData.disposableAmount || 0 }}</div>
          <el-link
            v-if="isTopOrg"
            :underline="false"
            type="primary"
            style="display: block;"
            @click="fundSetting"
          >
            资金管理设置
          </el-link>
          <el-button
            v-if="$auth('fin.vid.trans')"
            size="medium"
            class="trans-btn"
            type="primary"
            @click="onJumpTransfer"
          >
            转账
          </el-button>
          <div class="desc-item self-align-center">
            <el-link :underline="false" type="primary" @click="onGoToLiveBusiness">
              <span>业务明细</span>
              <i class="el-icon-arrow-right" />
            </el-link>
          </div>
        </div>
      </el-col>
    </div>
    <el-row
      v-else
      type="flex"
      :gutter="16"
      class="card-margin"
      style="height: 175px;"
    >
      <el-col :xs="12" :sm="12" :lg="14">
        <el-table
          style="width: 100%;"
          :indent="28"
          :data="accountLiveSummaryTableData"
          row-key="name"
          :span-method="arraySpanMethod"
          stripe
          lazy
        >
          <el-table-column align="center">
            <template slot="header">
              <span class="font-weight color-main">进行中的直播&会议活动</span>
            </template>
            <el-table-column
              prop="meetingMoney"
              align="center"
              min-width="120"
              label="状态"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.name || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="reviewing" align="center" label="待提交审核">
              <template slot-scope="scope">
                <span>{{ scope.row.reviewing || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="checking" align="center" label="审核中">
              <template slot-scope="scope">
                <span>{{ scope.row.checking || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="wait" align="center" label="审核成功待采购">
              <template slot-scope="scope">
                <span>{{ scope.row.pay || 0 }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col
        :xs="12"
        :sm="12"
        :lg="10"
        class="flex-around vertical-center desc-row"
      >
        <div class="desc-item">
          <el-popover
            placement="top-start"
            title="账户当日期初余额为账户前一日期末余额"
            trigger="hover"
          >
            <span slot="reference" class="color-main">期初余额&emsp;<i class="el-icon-question" /></span>
          </el-popover>
          <div class="font-s28 font-weight">{{ accountLiveSummaryData.startBalance || 0 }}</div>
          <div class="color-desc">
            截止至<br>
            {{ $moment().subtract(1,'day').endOf('day').format('YYYY.MM.DD HH:mm:ss') }}
          </div>
        </div>
        <div class="desc-item self-align-center">
          <el-divider direction="vertical" />
        </div>
        <div class="desc-item">
          <span class="color-main">实时可支配余额</span>
          <div class="font-s28 font-weight">{{ accountLiveSummaryData.disposableAmount || 0 }}</div>
          <el-link
            v-if="isTopOrg"
            :underline="false"
            type="primary"
            style="display: block;"
            @click="fundSetting"
          >
            资金管理设置
          </el-link>
          <el-button
            v-if="$auth('fin.vid.trans')"
            size="medium"
            class="trans-btn"
            type="primary"
            @click="onJumpTransfer"
          >
            转账
          </el-button>
        </div>
        <div class="desc-item self-align-center">
          <el-link :underline="false" type="primary" @click="onGoToLiveBusiness">
            <span>业务明细</span>
            <i class="el-icon-arrow-right" />
          </el-link>
        </div>
      </el-col>
    </el-row>
    <template v-if="$auth('fin.vid.viewExp')">
      <el-divider v-if="!isMobile" />
      <div class="font-weight">下级组织账户总览</div>
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="search-row flex-wrap"
      >
        <div>
          <el-date-picker
            :value="[startDate,endDate]"
            :disabled="isExport"
            type="daterange"
            range-separator="—"
            unlink-panels
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy年MM月dd日"
            :picker-options="pickerOptions"
            :editable="false"
            @change="onChangeDate"
          />
        &emsp;
          <el-radio-group v-model="activeRadio" @change="onChangeRadio">
            <el-radio-button v-for="item in radioList" :key="item.key" :label="item.key">
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
        &emsp;
          <el-cascader
            v-model="orgIds"
            :options="orgList"
            :props="cascaderProps"
            :show-all-levels="false"
            placeholder="全部"
            @change="onSelectOrg"
          />
        </div>
        <el-row
          v-if="!isMobile"
          type="flex"
          justify="space-between"
          align="middle"
        >
          <el-button plain class="plain-button-colored" @click="exportDialogVisible = true">导出数据</el-button>
        </el-row>
      </el-row>
      <div>
        <el-table
          v-if="!reqUpdate"
          v-loading="loading"
          style="width: 100%;"
          :data="initAccountLiveList"
          :indent="28"
          :expand-row-keys="defaultOpenArr"
          row-key="orgId"
          stripe
          lazy
          :load="onOpenOrgload"
          :tree-props="{children: 'child', hasChildren: 'hasChildren'}"
        >
          <el-table-column
            prop="orgName"
            width="180"
            label="所在组织"
            fixed="left"
            align="left"
            show-overflow-tooltip
          >
            <template slot="header">
              <div class="font-weight color-main align-center">组织名称</div>
            </template>
            <template slot-scope="scope">
              <div class="org-name-box">
                <el-tooltip
                  v-if="scope.row.status !== 1"
                  effect="light"
                  content="该组织已被冻结"
                  placement="bottom"
                >
                  <img src="@/assets/nav/forbid.png" alt="">
                </el-tooltip>
                {{ scope.row.orgName || '—' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="支出">
            <template slot="header">
              <div class="font-weight color-main">支出</div>
            </template>
            <el-table-column
              prop="serverMoney"
              align="center"
              label="视频采购服务"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.paySubTotal || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="paySubTotal" align="center" label="支出小计">
              <template slot-scope="scope">
                <span>{{ scope.row.paySubTotal || 0 }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="内部收支（充值及转账收支）">
            <template slot="header">
              <div class="font-weight color-main">内部收支（充值及转账收支）</div>
            </template>
            <el-table-column prop="chargeIncomeMoney" align="center" label="充值收入">
              <template slot-scope="scope">
                <span>{{ scope.row.chargeIncomeMoney || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="transferIncomeMoney" align="center" label="转账收入">
              <template slot-scope="scope">
                <span>{{ scope.row.transferIncomeMoney || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="otherIncome"
              align="center"
              width="120"
              label="其它收入"
            >
              <template slot="header">
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="top-start"
                >
                  <div slot="content">
                    <div>医生主动退回积分</div>
                  </div>
                  <span>其它收入<i class="el-icon-question" /></span>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.otherIncome || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="transferMoney" align="center" label="转账支出">
              <template slot-scope="scope">
                <span>{{ scope.row.transferMoney || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="incomeSubTotal" align="center" label="小计">
              <template slot-scope="scope">
                <span>{{ scope.row.incomeSubTotal || 0 }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :render-header="renderStartDate"
            prop="startBalance"
            align="center"
            width="150"
            label="期初结余"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.startBalance || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :render-header="renderEndDate"
            prop="balance"
            align="center"
            width="150"
            label="期末结余"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.balance || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="150"
            label="操作"
          >
            <template slot="header">
              <div class="font-weight color-main">操作</div>
            </template>
            <template v-if="scope.row.account !== '总计'" slot-scope="scope">
              <span class="text-primary-link" @click="onJumpDetail(scope.row)">详情</span>
              <el-popover
                v-model="openObj[scope.row.orgId]"
                width="200"
                trigger="manual"
                placement="left"
              >
                <div v-if="exportMsg" class="lay-box">{{ exportMsg }}</div>
                <div v-else class="lay-box">是否下载{{ startDate }}至{{ endDate }}的数据？</div>
                <el-row type="flex" justify="end">
                  <el-button size="mini" type="text" @click="onCloseToast(scope.row.orgId)">取消</el-button>
                  <el-button
                    v-if="exportMsg"
                    type="primary"
                    size="mini"
                    @click="onJumpDown"
                  >
                    前往
                  </el-button>
                  <el-button
                    v-else
                    type="primary"
                    size="mini"
                    :loading="isLoading"
                    @click="onDown(scope.row.orgId)"
                  >
                    确定
                  </el-button>
                </el-row>
                <span
                  slot="reference"
                  class="margin-l text-primary-link"
                  @click.stop="onShowDownToast(scope.row.orgId,true)"
                >下载</span>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <el-dialog
      append-to-body
      :visible.sync="exportDialogVisible"
      width="450px"
      title="导出数据"
    >
      <el-row
        type="flex"
        justify="space-around"
        align="middle"
        class="export-dialog"
      >
        <div @click="handleExportMarketingList">
          <img src="@/assets/common/summary_sheet.png" alt="">
          <div class="color-main">导出汇总表</div>
          <div class="color-info">导出所选组织的财务汇总数据</div>
        </div>
        <el-divider direction="vertical" />
        <div @click="handleExportMarketingList('new')">
          <img src="@/assets/common/detail_sheet.png" alt="">
          <div class="color-main">导出明细表</div>
          <div class="color-info">导出所选组织的财务明细数据</div>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {isMobile} from '@/utils/util.js'
export default {
  name: 'AccountLiveChild',
  components: {},
  filters: {
    downTipsFilter() {
      return ''
    }
  },
  data() {
    return {
      reqUpdate: true,
      orgIds: [],
      radioList: [{ name: '近一周', key: 'week' }, { name: '近一月', key: 'month' }, { name: '近三月', key: 'three_month' }],
      activeRadio: 'week', // message or download
      loading: false,
      pageNo: 1,
      flowType: 2, // 请求类型 1 积分 2直播服务费现金
      pageSize: 10,
      totalCount: 0,
      exportMsg: '',
      showMask: false,
      openObj: {}, // 页面弹窗控制对象
      cardBodyStyle: {
        paddingTop: '40px',
        paddingLeft: '36px',
        paddingRight: '36px',
        paddingBottom: '40px',
        minHeight: '600px'
      },
      cascaderProps: {
        children: 'child',
        label: 'orgName',
        value: 'orgId',
        disabled: 'isSelect',
        checkStrictly: true
      },
      exportDialogVisible: false,
      selectedOrgId: '',
      startDate: `${this.$moment().add(-1, 'weeks').format('YYYY-MM-DD')}`,
      endDate: `${this.$moment().add(-1, 'days').format('YYYY-MM-DD')}`,
      isExport: false,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.startDate = `${this.$moment(minDate).format('YYYY-MM-DD')}`
          this.endDate = `${this.$moment(maxDate).format('YYYY-MM-DD')}`
        },
        disabledDate: current => {
          // const tooLate = this.startDate && this.$moment(current).diff(this.$moment(this.startDate), 'days') > 365;
          // const tooEarly = this.endDate && this.$moment(this.endDate).diff(this.$moment(current), 'days') > 365;
          const currentMin = current < this.$moment('2018-01-01')
          const currentMax = current > this.$moment().add(-1, 'days')
          return currentMax || currentMin
        }
      },
      isSetAdmin: false,  // 是否设置管理员
      isLoading: false,
      moneyArr: ['N/A', 'serverMoney', 'webinarMoney', 'paySubTotal', 'chargeIncomeMoney', 'transferIncomeMoney', 'transferMoney', 'incomeSubTotal', 'balance'],
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapGetters(['accountLiveList', 'accountLiveSummaryData', 'orgList', 'factoryId', 'isTopOrg']),
    orgChildList() {
      return this.orgList[0] ? this.orgList[0].child : []
    },
    defaultOpenArr() {
      return this.accountLiveList.map(item => item.orgId)
    },
    initAccountLiveList() {
      const list = []
      if (this.accountLiveList[0]?.orgId) {
        list.push({ ...this.accountLiveList[0], hasChildren: null })
      }
      return list
    },
    accountLiveSummaryTableData() {
      const tableData = [
        {name: '直播',
          reviewing: this.accountLiveSummaryData.liveReviewingCount,
          checking: this.accountLiveSummaryData.liveCheckingCount,
          pay: this.accountLiveSummaryData.liveWaitPayCount
        },
        {name: '会议',
          reviewing: this.accountLiveSummaryData.webinarReviewingCount,
          checking: this.accountLiveSummaryData.webinarCheckingCount,
          pay: this.accountLiveSummaryData.webinarWaitPayCount
        },
        {name: '医学播客',
          reviewing: this.accountLiveSummaryData.podcastsReviewingCount,
          checking: this.accountLiveSummaryData.podcastsCheckingCount,
          pay: this.accountLiveSummaryData.podcastsWaitPayCount
        }
      ]
      console.log(tableData, '======= 9999')
      return tableData
    }
  },
  watch: {
    'orgList': {
      handler(val) {
        if (val && val[0]) {
          this.orgIds = [val[0].orgId]
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['onDownAccountData', 'getAccountLiveList', 'getAccountUserChildrenList', 'getIsOrgSetAdmin', 'downloadAccountCollectList', 'downloadAllDetailList', 'downloadDelegationAllCollectList']),
    onOpenOrgload(tree, treeNode, resolve) {
      console.log(tree, treeNode)
      const req = {
        flowType: this.flowType,
        targetOrgId: tree.orgId,
        startAt: this.startDate,
        endAt: this.endDate
      }
      this.loading = true
      this.getAccountUserChildrenList(req).then(res => {
        resolve(res)
      })
        .finally(() => {
          this.loading = false
        })
    },
    handleExportMarketingList(type) {
      const req = {
        flowType: this.flowType,
        targetOrgId: this.selectedOrgId,
        startAt: this.startDate,
        endAt: this.endDate
      }
      const request = type === 'all' ? this.downloadAllDetailList : type === 'new' ? this.downloadDelegationAllCollectList : this.downloadAccountCollectList
      request(req).then(res => {
        this.$message.success(res)
      }, rea => {
        this.$message.error(rea.message)
      })
      this.exportDialogVisible = false
    },
    onJumpDown() {
      this.$router.push('/download/center')
    },
    onDown(id) {
      if (!this.$moment(this.startDate).isValid()) return this.$message.error('请选择开始日期')
      if (!this.$moment(this.endDate).isValid()) return this.$message.error('请选择结束日期')
      if (this.isLoading) return
      this.isLoading = true
      const req = {
        startAt: this.startDate,
        endAt: this.endDate,
        targetOrgId: id,
        flowType: this.flowType
      }
      this.onDownAccountData(req).then(res => {
        this.isLoading = false
        this.$message.success('数据下载成功，请前往下载中心查看')
        this.exportMsg = res.data
        this.onCloseToast(id)
      }, rea => {
        this.isLoading = false
        this.$message.error(rea.message)
      })
    },
    init() {
      if (!this.$moment(this.startDate).isValid()) return this.$message.error('请选择开始日期')
      if (!this.$moment(this.endDate).isValid()) return this.$message.error('请选择结束日期')
      this.loading = true
      this.reqUpdate = true
      const req = {
        flowType: this.flowType,
        startAt: this.startDate,
        endAt: this.endDate,
        targetOrgId: this.selectedOrgId
      }
      this.getAccountLiveList(req).then(() => {
        this.loading = false
        this.reqUpdate = false
      }, rea => {
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    onSelectOrg(orgs) {
      const list = [...orgs]
      this.selectedOrgId = list.pop()
      this.init()
    },
    onChangeDate(dateArray) {
      this.activeRadio = ''
      if (!dateArray) {
        this.onChangeRadio('week')
      } else if (dateArray && dateArray.length === 2) {
        this.init()
      }
    },
    onChangeRadio(val) {
      this.activeRadio = val
      switch (this.activeRadio) {
        case 'week':
          this.startDate = `${this.$moment().add(-1, 'weeks').format('YYYY-MM-DD')}`
          this.endDate = `${this.$moment().add(-1, 'days').format('YYYY-MM-DD')}`
          break
        case 'month':
          this.startDate = `${this.$moment().add(-1, 'months').format('YYYY-MM-DD')}`
          this.endDate = `${this.$moment().add(-1, 'days').format('YYYY-MM-DD')}`
          break
        case 'three_month':
          this.startDate = `${this.$moment().add(-3, 'months').format('YYYY-MM-DD')}`
          this.endDate = `${this.$moment().add(-1, 'days').format('YYYY-MM-DD')}`
          break
      }
      this.init()
    },
    onCloseToast(openKey = '') {
      this.exportMsg = ''
      this.showMask = false
      this.openObj = { [openKey]: false }
    },
    onShowDownToast(openKey = '', status) {
      this.exportMsg = ''
      this.showMask = true
      this.openObj = { [openKey]: status }
    },
    onJumpTransfer() {
      this.$router.push('/account/live/transfer')
    },
    onGoToLiveBusiness() {
      this.$router.push({ path: '/account/live/business' })
    },
    onJumpDetail(item) {
      this.$router.push(`/account/payment/detail/live/${item.orgId || ''}?startDate=${this.startDate}&endDate=${this.endDate}&name=${item.account || ''}`)
    },
    onOpenCol() {
      this.isOpenCol = !this.isOpenCol
    },
    onChange(pageNo) {
      this.pageNo = pageNo
    },
    fundSetting() {
      this.$emit('setting')
    },
    arraySpanMethod({ rowIndex, columnIndex }) {
      if (rowIndex === 3) {
        if (columnIndex === 5) {
          return [1, 5]
        } else if (columnIndex > 0 && columnIndex < 5) {
          return [0, 0]
        }
      }
    },
    renderStartDate(h) {
      return h('div', { attrs: { class: 'font-weight color-main' } }, [`截止${this.startDate}`, h('br'), '期初结余'])
    },
    renderEndDate(h) {
      return h('div', { attrs: { class: 'font-weight color-main' } }, [`截止${this.endDate}`, h('br'), '期末结余'])
    }
  }
}
</script>

<style lang="scss">
  .org-name-box {
    display: inline;
    img {
      vertical-align: middle;
      margin-right: 5px;
    }
  }
  .account-live-child .el-table--border th {
    border-bottom: 1px solid #e1e1e1;
  }
  .account-live-child .el-table--border th,
  .el-table--border td {
    border-right: 1px solid #e1e1e1;
  }
  .account-live-child .el-table th:first-child > .cell {
    padding-left: 10px;
  }
  .account-live-child .el-table td:first-child > .cell {
    padding-left: 10px;
  }
</style>
<style lang="scss" scoped>
  .font-title {
    font-size: 36px;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .row-t {
    height: 220px;
  }
  .ly-box {
    background: linear-gradient(227deg, #3dc8fb, #417bf3);
    background-color: #3d61e3;
    position: relative;
    overflow: hidden;
    min-width: 300px;
  }
  .ly-box::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url("../../assets/doctor/user_less_bg.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .ly-box-p {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .ly-image {
    margin-right: 6px;
    flex-shrink: 0;
  }
  .trans-btn {
    position: relative;
    z-index: 10;
    width: 110px;
  }
  .margin-l {
    margin-left: 14px;
  }
  .nav-box {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .card-margin {
    margin-bottom: 10px;
  }
  .lay-box {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .table-header-row {
    padding-left: 20px;
  }
  .desc-row {
    padding: 10px 8px;
    align-items: flex-start;
  }
  .self-align-center {
    align-self: center;
  }
  .desc-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.9;
    min-height: 47px;
    button {
      transform: translateY(8px);
    }
    .el-divider {
      font-size: 47px;
    }
    i {
      color: $color-info;
    }
  }
  .search-row {
    padding: 12px 0;
  }
  .plain-button-colored {
    background: #fff;
    border-color: $color-primary;
    color: $color-primary;
    outline: none;
  }
  .export-dialog {
    padding: 20px;
    text-align: center;
    line-height: 1.75;
    div {
      cursor: pointer;
    }
    img {
      width: 30px;
      height: 35px;
      margin-bottom: 5px;
    }
    .el-divider {
      font-size: 37px;
    }
  }
</style>
