<template>
  <page-main v-if="$auth(['fin.pointsAccount', 'fin.vca'])" class="container customize-page-main">
    <el-tabs v-model="activeTab" @tab-click="onChange(activeTab)">
      <el-tab-pane
        v-for="item in tabList"
        :key="item.key"
        :label="item.name"
        :name="item.key"
      />
      <div class="lay-box">
        <AccountUserChild
          v-if="activeTab === 'user' && $auth('fin.pointsAccount')"
          @setting="fundsVisible = true"
        />
        <AccountLiveChild
          v-if="activeTab === 'live' && $auth('fin.vca')"
          @setting="fundsVisible = true"
        />
      </div>
    </el-tabs>
    <el-dialog
      title="资金管理设置"
      :visible.sync="fundsVisible"
      width="30%"
      class="customize-el-dialog"
      center
    >
      <br>
      <el-form>
        <el-form-item label="同级/跨级转账功能：" class="flex-center">
          <el-radio-group v-model="fundsSettings.onlyTransferChild">
            <el-radio :label="false">开启</el-radio>
            <el-radio :label="true">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label=" 上级撤回积分功能：" class="flex-center">
          <el-radio-group v-model="fundsSettings.isRecallSwitch">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label=" 上级使用下级账户支付直播会议："
          class="flex-center"
        >
          <el-radio-group v-model="fundsSettings.isAllowSuperPaySubOrgLiveFee">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fundsVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="changeFundsSetting">
          确定
        </el-button>
      </span>
    </el-dialog>
  </page-main>
</template>
<script>
import AccountLiveChild from './AccountLiveChild'
import AccountUserChild from './AccountUserChild'
import {
  getAccountFactorySetting,
  updateAccountFactorySetting
} from '@/services/accountService'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AccountLayout',
  components: {
    AccountUserChild,
    AccountLiveChild
  },
  data() {
    return {
      activeTab: 'user',
      tabList: [
        { name: '积分账户', key: 'user', auth: 'fin.pointsAccount' },
        { name: '视频采购账户', key: 'live', auth: 'fin.vca' }
      ].filter(item => item.auth ? this.$auth(item.auth) : true),
      fundsVisible: false,
      fundsSettings: {
        onlyTransferChild: false,
        isRecallSwitch: false,
        isAllowSuperPaySubOrgLiveFee: false
      }
    }
  },
  computed: {
    ...mapGetters(['factoryId'])
  },
  activated() {
    this.activeTab = this.tabList[0]?.key || ''

    this.activeTab === 'user'
      ? this.getAccountUserSummary()
      : this.getAccountLiveSummary()
    this.init()
  },
  methods: {
    ...mapActions([
      'getAccountUserSummary',
      'getAccountLiveSummary',
      'getOrgList'
    ]),
    onChange(tab) {
      tab === 'user'
        ? this.getAccountUserSummary()
        : this.getAccountLiveSummary()
    },
    async init() {
      !this.factoryId && (await this.getOrgList())
      getAccountFactorySetting({ uOrgId: this.factoryId })
        .then(res => {
          if (res.code !== 0) {
            this.$message.error(res.message)
            return
          }
          this.fundsSettings = { ...this.fundsSettings, ...res.data }
        })
        .catch(e => {
          this.$message.error(e.message)
          console.log(e)
        })
    },
    changeFundsSetting() {
      updateAccountFactorySetting({
        uOrgId: this.factoryId,
        ...this.fundsSettings
      })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('设置成功')
            this.fundsVisible = false
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(e => {
          this.$message.error(e.message)
          console.log(e)
        })
    }
  }
}
</script>

