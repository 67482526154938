<template>
  <page-main title back>
    <el-tabs
      v-model="activeName"
      style="margin-top: -10px;"
      @tab-click="handleClick"
    >
      <el-tab-pane label="业务明细" name="first">
        <el-row>
          <el-col :span="12">
            <el-date-picker
              v-model="search.date"
              class="holder-color"
              format="yyyy年MM月dd日"
              clearable
              value-format="yyyy/MM/dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              type="daterange"
              range-separator="-"
              :editable="false"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              @change="onDateChange"
              @clear="
                search.pageNo = 1
                getData()
              "
            />
          </el-col>
          <el-col v-if="!isMobile" :span="12" style="text-align: right;">
            <el-button @click="onExport">导出Excel</el-button>
          </el-col>
        </el-row>
        <el-table
          v-loading="loading"
          class="financial-table mgn-t10"
          :data="accountLiveBusinessList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          stripe
          @sort-change="onSortChange"
        >
          <el-table-column align="center" prop="date" label="时间" />
          <el-table-column align="center" prop="sceneType" label="类型" />
          <el-table-column align="center" prop="programName" label="活动名称" />
          <el-table-column align="center" prop="amount" label="视频采购费" />
          <el-table-column align="center" prop="payType" label="收入/支出" />
          <el-table-column align="center" prop="paidUserInfo" label="操作人" />
          <el-table-column
            label="所在组织"
            width="200"
            show-overflow-tooltip
            prop="orgName"
            fixed="right"
            align="center"
          />
        </el-table>
        <el-row type="flex" justify="end" class="t-p">
          <el-pagination
            background
            hide-on-single-page
            layout="prev, pager, next"
            :page-size="search.pageSize"
            :current-page="search.pageNo"
            :small="true"
            :total="totalCount"
            @current-change="onChange"
          />
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </page-main>
</template>
<script>
import {
  getSourceList,
  getSceneList,
  getLiveBusinessListDown,
  getAccountLiveBusinessList
} from '@/services/accountService'
import { isMobile } from '@/utils/util.js'

export default {
  name: 'AccountLiveBusiness',
  data() {
    return {
      activeName: 'first',
      totalCount: 0,
      loading: false,
      sceneList: [],
      sourceList: [],
      amountList: [
        {
          name: '收入',
          type: '1'
        },
        {
          name: '支出',
          type: '-1'
        }
      ],
      search: {
        // 表格筛选
        date: [],
        pageNo: 1,
        pageSize: 10,
        sceneType: '', // 类型
        sourceType: '', // 摘要
        changeType: '', // 收支
        isAsc: false,
        startTime: '',
        endTime: ''
      },
      accountLiveBusinessList: [],
      pickerOptions: {
        disabledDate: current => {
          const currentMin = current < this.$moment('2018-01-01')
          const currentMax = current > this.$moment().endOf('day')
          return currentMax || currentMin
        }
      },
      isMobile: isMobile()
    }
  },
  mounted() {
    this.getData()
    // 查询积分摘要列表
    getSourceList().then(res => {
      const { data } = res
      this.sourceList = data
    })
    // 场景列表
    getSceneList().then(res => {
      const { data } = res
      this.sceneList = data
    })
  },
  methods: {
    handleClick() {
      if (this.activeName === 'first') {
        this.getData()
      }
      if (this.activeName === 'second') {
        this.getWithdrawData()
      }
    },
    // 获取积分明细列表数据
    async getData() {
      this.loading = true
      const params = Object.assign({}, this.search)
      delete params.date
      try {
        const res = await getAccountLiveBusinessList(params)
        this.loading = false
        this.accountLiveBusinessList = res?.data?.data || []
        this.totalCount = res?.data?.totalCount
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$message.error(error?.message)
      }
    },
    // 表格排序
    onSortChange(arg) {
      if (arg.prop === 'date') {
        this.search.isAsc = arg.order === 'ascending'
      }
      this.getData()
    },
    onChange(page) {
      this.search.pageNo = page
      this.getData()
    },
    // 导出数据
    async onExport() {
      console.log(this.search)
      const params = Object.assign({}, this.search)
      delete params.date
      delete params.pageNo
      delete params.pageSize
      console.log(params)

      try {
        const { data } = await getLiveBusinessListDown(params)
        this.$message({
          type: 'success',
          message: data
        })
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 日期选择
    onDateChange(value) {
      if (!value) {
        this.search.startTime = ''
        this.search.endTime = ''
        this.getData()
        return
      }
      const [startTime = '', endTime = ''] = value
      this.search.startTime = startTime.replace(/\//gi, '-')
      this.search.endTime = endTime.replace(/\//gi, '-')
      this.search.pageNo = 1
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  padding: 0;
}
::v-deep .financial-table {
  .cell:empty::before {
    content: '/';
    color: gray;
  }
}
::v-deep .holder-color {
  input,
  .el-range-input {
    &::-webkit-input-placeholder {
      color: rgba(96, 98, 102, 0.8);
    }
    &::-moz-placeholder {
      color: #606266;
    }
    &:-ms-input-placeholder {
      color: #606266;
    }
  }
}
::v-deep .el-card__body {
  padding: 0 20px 20px;
}
::v-deep .el-table td:first-child > .cell,
.el-table th:first-child > .cell {
  padding-left: 0;
}
.search {
  .form {
    display: flex;
    justify-content: flex-end;
    > div {
      margin-left: 20px;
    }
  }
}
.lay-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.t-p {
  padding-top: 15px;
}
</style>
